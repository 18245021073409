/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --body-background: #f2f2f2;
  --body-color: #444;
  --heading-color: black;
  --action-color: #d64045;
}

br.mobile {
  @apply md:hidden
}

.prose-menu {
  @apply py-8 md:py-24 m-auto prose-em:text-site-orange prose-em:not-italic prose-p:text-site-text prose-h2:uppercase prose-h2:text-site-header prose-h2:font-header font-thin text-site-text text-xl md:text-2xl prose-h2:font-normal prose-h2:text-4xl md:prose-h2:text-5xl prose-h1:text-site-header prose-h1:font-header prose-h1:text-center prose-h1:text-7xl prose-h1:tracking-wider prose-h2:tracking-wide tracking-wide md:prose-h2:text-left md:prose-p:text-left prose-p:font-sans prose-h3:font-header prose-h3:uppercase prose-h3:text-site-orange prose-h3:font-normal  prose-h3:m-0 prose-p:mt-0 prose-h2:mb-2
}

.item {
  @apply  break-inside-avoid-column
}

.prose-site {
  @apply py-8 md:py-24 m-auto prose-em:text-site-orange prose-em:not-italic prose-p:text-site-text prose-h2:uppercase prose-h2:text-site-header prose-h2:font-header font-thin text-site-text text-xl md:text-2xl prose-h2:font-normal prose-h2:text-4xl md:prose-h2:text-5xl prose-h1:text-site-header prose-h1:font-header prose-h1:text-center prose-h1:text-7xl prose-h1:tracking-wider prose-h2:tracking-wide tracking-wide prose-h2:text-center md:prose-h2:text-left prose-p:text-center md:prose-p:text-left prose-p:font-sans prose-h3:font-header prose-h3:uppercase prose-h3:text-site-orange prose-h3:font-normal
}

.prose-site-dark {
  @apply m-auto prose-em:text-site-orange prose-em:not-italic prose-p:text-white prose-h2:uppercase prose-h2:text-white prose-h2:font-header font-thin text-site-text text-base prose-h2:mb-2 prose-h2:font-normal prose-h2:text-5xl prose-h1:text-site-header prose-h1:font-header prose-h1:text-center prose-h1:text-7xl prose-h1:tracking-wider prose-h2:tracking-wide tracking-wide  prose-h3:font-header prose-h3:uppercase prose-h3:text-site-orange prose-h3:font-normal
}

.menu {
  @apply prose py-24 m-auto prose-em:text-site-orange prose-em:not-italic prose-p:text-site-text prose-h2:uppercase prose-h2:text-site-header prose-h2:font-header font-thin text-site-text text-2xl prose-h2:font-normal prose-h2:text-5xl prose-h1:text-site-header prose-h1:font-header prose-h1:text-center prose-h1:text-7xl prose-h1:tracking-wider prose-h2:tracking-wide tracking-wide prose-h3:text-site-orange prose-h3:m-0 prose-h3:text-xl;
}
.menuheader {
}
.menuitem { 
  @apply break-inside-avoid-column flex flex-row flex-wrap items-baseline gap-4;  
}
.menuitem h3 {
  @apply order-1
}
.menuitem .price {
  @apply text-sm order-2;
}
.menuitem .description {
  @apply text-base order-3 w-full;
}
.imgbutton {
  @apply text-white  text-4xl md:text-6xl font-header tracking-wider border-4 py-8 px-16 bg-black bg-opacity-30 uppercase absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
}

a.orangebutton {
  @apply text-site-orange  text-xl md:text-3xl font-header tracking-wider border-4 border-site-orange py-4 px-8 uppercase block m-auto text-center no-underline
}


section {
  @apply relative z-0 p-4 md:p-12;
}

section::before {
    background-image: url('/images/bluebars.png'); 
    content: "";
    @apply w-4 hidden md:block md:absolute left-4 bg-repeat-y m-0 p-0 bg-contain;
    top: 0px;
    bottom: 0px;
    overflow: hidden;
}
section::after {
    background-image: url('/images/bluebars.png'); 
    content: "";
    @apply w-4 hidden md:absolute md:block right-4 bg-repeat-y m-0 p-0 bg-contain;
    top: 0px;
    bottom: 0px;
    overflow: hidden;
}
.footer1::before {
    background-image: url('/images/greybars.png'); 
    content: "";
    @apply w-4 hidden md:absolute left-4 md:block bg-repeat-y m-0 p-0 bg-contain;
    top: 0px;
    bottom: 0px;
    overflow: hidden;
}
.footer1::after {
    background-image: url('/images/greybars.png'); 
    content: "";
    @apply w-4 md:absolute right-4 md:block bg-repeat-y m-0 p-0 bg-contain hidden ;
    top: 0px;
    bottom: 0px;
    overflow: hidden;
}
.footer1 {
  @apply relative z-0 p-12;
}

.prose-site::before {
    background-image: url('/images/threestripes.svg'); 
    content: "";
    @apply absolute top-4 left-4 md:hidden inline-block h-8 w-10 bg-contain bg-no-repeat ; 
}
.prose-site::after {
    background-image: url('/images/threestripes2.svg'); 
    content: "";
    @apply absolute top-4 right-2 md:hidden inline-block h-8 w-10 bg-contain bg-no-repeat ; 
}

h2.menusection::before {
    background-image: url('/images/threestripes.svg'); 
    content: "";
    @apply inline-block h-6 w-8 md:h-[34px] md:w-12 bg-contain bg-no-repeat mr-0; 
}
h1.xmas::before {
    background-image: url('/images/threestripes.svg'); 
    content: "";
    @apply hidden md:inline-block h-[52px] w-[60px] bg-cover bg-no-repeat mr-4; 
}
h1.xmas::after {
    background-image: url('/images/threestripes2.svg'); 
    content: "";
    @apply hidden md:inline-block h-[52px] w-[60px] bg-cover bg-no-repeat ml-4; 
}
.mobilehome {
  background-image: url('/images/mobilesteak.jpg'); 
  @apply h-screen bg-cover relative bg-center md:hidden;
}

.btn {
  @apply px-8 py-4 text-2xl text-white bg-site-orange block m-4 font-header uppercase col-span-1 tracking-wider
}
